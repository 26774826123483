<template>
  <div class="Field">
    <template v-if="type === 'select'">
      <van-field
        readonly
        clickable
        :name="name"
        :value="value"
        :label="label"
        label-class="i-field-label-align"
        :required="field.filedRequired"
        :placeholder="placeholder"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="list"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
    </template>
    <template v-else-if="type === 'radio'">
      <van-field label-class="i-field-label-align" :name="name" :label="label" :placeholder="placeholder" :required="field.filedRequired">
        <template #input>
          <van-radio-group :value="value" direction="horizontal">
            <van-radio icon-size="14px" v-for="(item,index) in list" :key="index" :name="item" @click="onRadioChange(item)">{{ item }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </template>
    <template v-else-if="type === 'textarea'">
      <van-field
        label-class="i-field-label-align"
        :required="field.filedRequired"
        :placeholder="placeholder"
        :label="label"
        :value="value"
        rows="3"
        autosize
        type="textarea"
        @input="onInput"
      />
    </template>
    <template v-else>
      <van-field
        label-class="i-field-label-align"
        :required="field.filedRequired"
        :placeholder="placeholder"
        :label="label"
        :value="value"
        @input="onInput"
      />
    </template>
  </div>
</template>

<script>

import { Field, Stepper, RadioGroup, Radio, Picker } from 'vant'

export default {
  name: 'Field',
  components: {
    [Stepper.name]: Stepper,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Field.name]: Field,
    [Picker.name]: Picker
  },
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    index: {
      type: [String, Number],
      default: ''
    },
    parentIndex: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text' // text,select,radio,textarea
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    val: {
      type: [String, Number],
      default: ''
    },
    vals: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // select
      showPicker: false,

      list: this.vals
    }
  },
  computed: {
    value() {
      return this.val
    }
  },
  created() {

  },
  methods: {
    // select
    onConfirm(value) {
      this.showPicker = false
      this.$emit('change', { index: this.index, value, parentIndex:this.parentIndex })
    },
    // radio
    onRadioChange(value) {
      this.$emit('change', { index: this.index, value, parentIndex:this.parentIndex })
    },
    // text,textarea
    onInput(value) {
      this.$emit('change', { index: this.index, value, parentIndex:this.parentIndex })
    }
  }
}
</script>
<style lang="less">
  .i-field-label-align{
    width: 65px;
    margin-left: 5px;
    text-align: justify;
    text-align-last:justify;
    height: 10px;
  }
  .van-field__label:after {
      content: '';
      width: 100%;
      display: inline-block;
   }
</style>

